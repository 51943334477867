import React from "react"
import ContentCard from "./contentCard"
import researchData from "../data/research"
import styles from "../styles/research.module.scss"

export default function ResearchItems() {
  return (
    <div className={styles.research}>
      {researchData.map((researchItem, index) => {
        let isInternal = false
        let itemUrl = researchItem.documentUrl

        if (researchItem.documentUrl.includes(".pdf")) {
          isInternal = true
          itemUrl = `/research${researchItem.documentUrl}`
        }

        return (
          <ContentCard
            key={researchItem.title}
            slug={itemUrl}
            title={researchItem.title}
            image={researchItem.imageUrl}
            content={researchItem.excerpt}
            buttonText="View Research"
            buttonUrl={itemUrl}
            isInternal={isInternal}
            type={isInternal ? "pdf" : "external"}
            index={index}
          />
        )
      })}
    </div>
  )
}
