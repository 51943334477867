const data = [
  {
    title: "Inter-Rebel Alliances",
    imageUrl: "https://i.imgur.com/duamrqE.jpg",
    documentUrl: "/alliances.pdf",
    excerpt:
      "From the Patriotic Front struggle against the minority rule in Rhodesia to the seven-party mujaheddin alliance in Afghanistan, inter-rebel alliances make the armed opposition more resilient and successful in the face of government repression. Why then do some rebel groups cooperate with each other while others do not?",
  },
  {
    title: "Managing Internationalized Civil Wars",
    imageUrl: "https://i.imgur.com/Q8vwe8I.jpg",
    documentUrl: "/managing.pdf",
    excerpt:
      "In their seminal study “Resort to Arms,” Small and Singer (1982) defined a civil war as “any armed conflict that involves (a) military action internal to the metropole, (b) the active participation of the national government, and (c) effective resistance by both sides.” ",
  },
  {
    title: "Rhetoric of Civil Conflict Management",
    imageUrl: "https://i.imgur.com/iYYs0v6.jpg",
    documentUrl: "/rhetoric.pdf",
    excerpt:
      "This paper introduces a spatial model of civil conflict management rhetoric to explore how the emerging norm of responsibility to protect shapes major power rhetorical responses to civil war.",
  },
  {
    title: "The Perils of Weak Organization",
    imageUrl: "https://i.imgur.com/IEmR0fX.jpg",
    documentUrl:
      "http://www.tandfonline.com/doi/abs/10.1080/1057610X.2015.1063838",
    excerpt:
      "Why do some militant groups defect against their sponsors, while others remain loyal?",
  },
  {
    title: "Fragile Proxies",
    imageUrl: "https://i.imgur.com/YkoZ4xQ.jpg",
    documentUrl:
      "http://www.tandfonline.com/doi/abs/10.1080/09546553.2015.1092437",
    excerpt:
      "Foreign governments frequently intervene in armed conflicts by sponsoring rebels against their adversaries. A sponsorship is less costly than a direct military intervention, but rebels often defy orders, desert fighting, or turn guns against their sponsors.",
  },
  {
    title: "Charm Offensive or Offensive Charm?",
    imageUrl: "https://imgur.com/T54Ac1v.jpg",
    documentUrl:
      "https://www.tandfonline.com/doi/full/10.1080/09668136.2020.1785397",
    excerpt:
      "Major powers have long used cultural institutes to enhance their appeal in foreign countries. As aspirant powers, Russia and China have recently launched cultural institutes of their own with the aim of improving their international reputations.",
  },
  {
    title:
      "Unilateral secession, international recognition, and great power contestation",
    imageUrl: "https://imgur.com/X5xG6U7.jpg",
    documentUrl: "/unilateral-secession-international-recognition-and-great-power-contestation.pdf",
    excerpt:
      "Previous research suggests that great power recognition decisions regarding aspiring states rapidly converge toward either recognition or non-recognition, yet great power convergence has still not occurred in the case of Kosovo after more than ten years.",
  },
  {
    title:
      "The Effect of Aerial Bombing on Voting for The Incumbent in Competitive Autocracies",
    imageUrl: "https://imgur.com/DNC2XsQ.jpg",
    documentUrl: "/strongmen-cry-too-effect-of-aerial-bombing-on-voting-for-incumbent-in-competitive-autocracies.pdf",
    excerpt:
      "This article analyzes the effect of the 1999 NATO bombing of Yugoslavia on Serbian local elections using difference-in-differences identification strategy and identifies the effect of air strikes on the vote-share of Slobodan Milosevic's regime.",
  },
  {
    title:
      "Operation Allied Force: Unintended Consequences of the NATO Bombing on Children Outcomes",
    imageUrl: "https://imgur.com/XP1gMwd.jpg",
    documentUrl: "/operation-allied-force-unintended-consequences-of-the-nato-bombing-on-children-outcomes.pdf",
    excerpt:
      "This is the first paper that estimates the causal effect of the NATO’s Operation Allied Force in Serbia in 1999, on children who were in the womb during the bombing.",
  },
  {
    title:
      "Purifying the Religion: An Analysis of Haram Targeting among Salafi Jihadi Groups",
    imageUrl: "https://imgur.com/al1S3DW.jpg",
    documentUrl: "/purifying-the-religion-an-analysis-of-haram-targeting-among-salafi-jihadi-groups.pdf",
    excerpt:
      "This article suggests that there is a clear logic to haram targeting that can be derived from Salafi-jihadi groups’ ethnic structure.",
  },
]

export default data
